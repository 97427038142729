function getLabelBase () {
  return {
    extraLineBefore: '',
    discount: '',
    extraLineAfter: '',
    qty: ''
  };
}

function getRegexOffDiscount() {
  return /([0-9]+%) Off en la (.*? unidad)/g
}

function getRegexOffAlternative() {
  return /([0-9]+%) Off (llevando .*? unidades)/g;
}

function getRegex50OrMoreDiscount() {
  return /([0-9]+) x ([0-9]+)/g;
}

function getRegexUpToDiscount() {
  return /Hasta ([0-9]+%) Off/g;
}

function parsePromotion (promotion) {
  let matches;
  const label = getLabelBase();

  if ((matches = getRegexOffDiscount().exec(promotion)) !== null) {
    return parsePromotionOffDiscount(matches);
  } else if ((matches = getRegexOffAlternative().exec(promotion)) !== null) {
    return parsePromotionOffDiscount(matches);
  } else if ((matches = getRegexUpToDiscount().exec(promotion)) !== null) {
    return parsePromotionUpToDiscount(matches);
  } else if ((matches = getRegex50OrMoreDiscount().exec(promotion)) !== null) {
    label.discount = matches.input;
  } else {
    label.discount = promotion;
  }
  return label;
}

function parsePromotionOffDiscount(matches) {
    const label = getLabelBase();
    label.extraLineAfter = "Off";
    matches.forEach((match, groupIndex) => {
      if (groupIndex === 1) {
        label.discount = match;
      } else if (groupIndex === 2) {
        label.qty = match;
      }
    });
    return label;
}

function parsePromotionUpToDiscount(matches) {
    const label = getLabelBase();
    label.extraLineBefore = "hasta";
    label.extraLineAfter = "Off";
    matches.forEach((match, groupIndex) => {
      if (groupIndex === 1) {
        label.discount = match;
      }
    });
    return label;
}

module.exports = {
  parsePromotion
}
