import React  from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import toastConfig from './toastConfig';

export default function requireAuth(Component) {
    class AuthenticatedComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            logged:  localStorage.getItem('token')
            }
        }
        componentDidMount() {
            this.checkAuth();
        }
        checkAuth() {
                if ( !localStorage.getItem('token') && this.props.location.pathname !== '/sign-in') {
                    this.props.history.push(`/sign-in`);
                }
                if ( localStorage.getItem('token') && this.props.location.pathname === '/sign-in') {
                    this.props.history.goBack();
                    toast.warn('Ya has iniciado sesión. Presiona CERRAR SESIÓN para salir', toastConfig.warnConf);
                }
            }
        render() {
            return localStorage.getItem('token') ? <Component { ...this.props } /> : null;
        }
    }
    return  withRouter(AuthenticatedComponent)
}
