import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import serverUrl from './endpoints';
import { toast } from 'react-toastify';
import toastConfig from './toastConfig';

import List from './list.component';
import TableToExcel from './tableToExcel';


import right from './../assets/chevron-right.svg';
import filters from './../assets/filter.svg';


import { ReactComponent as InTravel } from './../assets/en-transito.svg';
import { ReactComponent as Canceled } from './../assets/cancelado.svg';
import { ReactComponent as Available } from './../assets/disponible.svg';
import { ReactComponent as Delivered } from './../assets/entregado.svg';



const HistoryOrder = () => {
    const [areFiltersExpanded, setExpandFilters] = useState(0);
    const [dropButton, setDropButton] = useState(false);
    const [orders, setOrders] = useState({
        orders: {}
    });

    const [selectedMonth, setSelectedMonth] = useState({
        value: ""
    });
    const [search, setSearch] = useState({
        count: 0,
        results: []
    });
    const orderStatusList = [
        "en_transito",
        "disponible",
        "entregada",
        "cancelado",
        "no_retirada"
    ];
    const monthArr = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ];

    useEffect(() => {
        getOrders();
    }, [selectedMonth]);

    const handleSelectMonth = (e) => {
        e.preventDefault();
        setSelectedMonth({ value: e.target.value })
        setDropButton(true);
        setExpandFilters(0);
    };

    const handleClearMonth = (e) => {
        e.preventDefault();
        setSelectedMonth({ value: '' });
        clearSearch();
        setDropButton(false);
    };

    const currentMonth = () => {
        const date = new Date();
        return date.getMonth();
    };

    const getMonth = useMemo(() =>{
        const month = currentMonth();
        let arrMonths = monthArr.slice((month - 2), (month + 1));

        return arrMonths ? arrMonths.map((month, i) =>
            <button  className="dropdown-item" href="javascript;" value={month} key={i} onClick={(e) => handleSelectMonth(e)}>{month}</button>
        ) : null
    },[currentMonth]);

    const clearSearch = () => {
        // just clean up the search results to start over
        updateSearch({
            results: {},
            count: 0
        });
        // @TODO: refactor entire tab system
        // This workaround needs to be done so, when you clear a search
        // the active tab becomes inactive and the first one is selected
        //
        // Causes: given the app is currently built to parse and control the Orders
        // within this component, when the search gets refreshed/cleaned, only the <List /> comps
        // are re-rendered (not the entire dash component).
        // This causes a dissociation between the `active` marked tab,
        // and which `<List />` comp is shown right after the search is cleaned.
        let tabs = document.getElementById('myTab');
        tabs.getElementsByClassName('active')[0].classList.remove('active');
        tabs.getElementsByTagName('a')[0].classList.add('active');
    };

    /**
     * Reshape data structure so all orders are within a proper `status` node
     * [
     *   "<order_status>": [ {...}, {...}]
     *   "en_transito": [ {"increment_id":"xxxx", ...}, {...}]
     * ]
     *
     * @param {object[]}
     * @return {string[object[]]}
     */
    const normalizeOrderDataStructure = (orders) => {
        var shaped = orders.reduce((mapping, order) => {
            if (!mapping[order.status]) {
                mapping[order.status] = [];
            }

            mapping[order.status].push(order);
            return mapping;
        }, {});

        // ensure to populate all status,
        // no matter if we have orders or not
        orderStatusList.forEach(status => {
            if (!shaped[status]) {
                shaped[status] = [];
            }
        });
        return shaped;
    };

    const applySearchFilter = (orders) => {

        if (selectedMonth.value !== '' && orders) {

            let list = filterOrders(orders, selectedMonth.value);
            updateSearchResults(list);
            return list;
        }
        return orders;
    };

    /**
     * @param {object[]} filteredOrders list of orders matching search criteria
     */
    const updateSearchResults = (filteredOrders) => {
        let counter = 0;

        Object.keys(filteredOrders)
            .forEach(status => {
                counter = counter + filteredOrders[status].length;
            });

        updateSearch({
            results: filteredOrders,
            count: counter
        });

        setOrders({ orders: filteredOrders });
    };

    /**
    * @param  {Object} searchData
    */
    const updateSearch = (searchData) => {
        setSearch({
            ...search,
            ...searchData
        });
    };

    /**
     * @param  {string} haystack month value
     * @param  {object[]} orders list of orders
     * @return {object[]} orders matching the haystack
     */
    const filterOrders = (orders, month) => {

        const haystack = monthArr.indexOf(month);

        let filtered = {};

        Object.keys(orders)
            .forEach(status => {
                filtered[status] = orders[status].filter(order => {
                    return order.hasOwnProperty('created_at')
                        && (new Date(order.created_at).getMonth() + 1) === (haystack + 1)
                });
            }
            );
        return filtered;
    };

    const getOrders = () => {
        return axios.get(serverUrl.orders())
            .then(res => {
                if (res.status === 200) {
                    let orderGroup = normalizeOrderDataStructure(
                        Object.values(res.data)

                    );
                    return orderGroup;
                } else {
                    toast.error('Ha ocurrido un error cargando tus pedidos.', toastConfig.errorConf)
                }
            })
            .then((orders) => {
                if (!orders) {
                    return;
                }
                orders = applySearchFilter(orders);
                setOrders({ orders: orders });
            })
            .catch(error => {
                console.log('dash:getOrders:error', error);
                if (error.message === 'token_expired') {
                    toast.error('Su sesion ha caducado, por favor inicie sesion nuevamente.', toastConfig.errorConf)
                }
            });
    };

    return (
        <div className="holder">
            <div className="tab-content" >
                <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="home-tab">
                    <div className="stateList">
                        <div className="listFilter">
                            <span className="bold text-uppercase">Historial de Pedidos</span>
                            <div className="nav-item dropdown">
                                <button className="nav-link dropdown-toggle bold" id="navbarDropdown" onClick={() => setExpandFilters(!areFiltersExpanded)}>
                                    <img src={filters} alt="Filtros" />
                                    Ordenar por mes
                                </button>
                                <div aria-labelledby="navbarDropdown" className={areFiltersExpanded ? 'dropdown-menu block' : 'dropdown-menu'}>
                                    {getMonth}
                                </div>
                            </div>
                            {
                                dropButton ?
                                    <button className="nav-link dropdown-toggle selected" id="navbarDropdown" onClick={handleClearMonth} >
                                        {selectedMonth.value}
                                        <span className="dismissFilter">X</span>
                                    </button> : null
                            }
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-pills nav-fill" id="myTab" role="tablist" style={{flexWrap:"nowrap"}}>
                <li className="nav-item">
                    <a className="nav-link text-uppercase active bold" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                        <InTravel />
                        <span>en transito</span>
                        <img className="chevron" alt="chevron" src={right} />
                    </a>
                    {(selectedMonth.value && orders.orders.en_transito && orders.orders.en_transito.length > 0) &&
                        <strong>{orders.orders.en_transito.length}</strong>
                    }
                </li>
                <li className="nav-item">
                    <a className="nav-link text-uppercase" id="available-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                        <Available />
                        <span>disponible</span>
                        <img className="chevron" alt="chevron" src={right} />
                    </a>
                    {(selectedMonth.value && orders.orders.disponible && orders.orders.disponible.length > 0) &&
                        <strong>{orders.orders.disponible.length}</strong>}
                </li>
                <li className="nav-item">
                    <a className="nav-link text-uppercase" id="delivered-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                        <Delivered />
                        <span>entregado</span>
                        <img className="chevron" alt="chevron" src={right} />
                    </a>
                    {(selectedMonth.value && orders.orders.entregada && orders.orders.entregada.length > 0) &&
                        <strong>{orders.orders.entregada.length}</strong>}
                </li>
                <li className="nav-item">
                    <a className="nav-link text-uppercase" id="cancelled-tab" data-toggle="tab" href="#cancel" role="tab" aria-controls="contact" aria-selected="false">
                        <Canceled />
                        <span>cancelado</span>
                        <img className="chevron" alt="chevron" src={right} />
                    </a>
                    {(selectedMonth.value && orders.orders.cancelado && orders.orders.cancelado.length > 0) &&
                        <strong>{orders.orders.cancelado.length}</strong>}
                </li>
                <li className="nav-item">
                        <a className="nav-link text-uppercase"  id="unshiped-tab" data-toggle="tab" href="#no_retirada" role="tab" aria-controls="contact" aria-selected="false">
                        <Canceled />
                           <span>No retirada</span>
                           <img className="chevron" alt="chevron" src={right}/>
                        </a>
                        { (selectedMonth.value && orders.orders.no_retirada && orders.orders.no_retirada.length > 0 ) &&
                        <strong>{ orders.orders.no_retirada.length }</strong> }
                    </li>
                <div className='nav-item'>
                    <TableToExcel orders={orders} month={selectedMonth}/>
                </div>
            </ul >
            {!orders.length ? (
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <List byOrder="en_transito" orders={orders.orders.en_transito} changeStatusOrder={true} />
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <List byOrder="disponible" orders={orders.orders.disponible} changeStatusOrder={true} />
                    </div>
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <List byOrder="entregada" orders={orders.orders.entregada} changeStatusOrder={true} />
                    </div>
                    <div className="tab-pane fade" id="cancel" role="tabpanel" aria-labelledby="contact-tab">
                        <List byOrder="cancelado" orders={orders.orders.cancelado} changeStatusOrder={true} />
                    </div>
                    <div className="tab-pane fade" id="no_retirada" role="tabpanel" aria-labelledby="contact-tab">
                        <List byOrder="no_retirada" orders={orders.orders.no_retirada} changeStatusOrder={true}/>
                    </div>
                </div >
            ) : null}
        </div >
    );
};

export default HistoryOrder;
