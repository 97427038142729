import React, { Component } from 'react'
import axios from 'axios';
import serverUrl from './endpoints';
import toastConfig from './toastConfig';
import { Link} from 'react-router-dom';
import Logo  from './../assets/logoGPS.svg';
import { toast } from 'react-toastify';
import './../styles/login.scss';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            user: '',
            password: '',
            formErrors: {name: '', user: '', password: ''},
            userValid: false,
            passwordValid: false,
            formValid: false,
            pharmacyName: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        // @TODO: reimplement at Auth logic level
        // by redirecting, dashboard should intercept and detect a 401
        // in case token has expired
        if (localStorage.getItem('token')) {
          this.props.history.push('/');
        }
    }

    validateField(fieldName, value) {
      let fieldValidationErrors = this.state.formErrors;
      let userValid = this.state.userValid;
      let passwordValid = this.state.passwordValid;
      let nameValid = this.state.nameValid;

      switch(fieldName) {
          case 'name':
              nameValid = value.length >= 3;
              fieldValidationErrors.name = nameValid ? '': 'El nombre es muy corto.';
              break;
          case 'user':
              userValid = value.length >= 3;
              fieldValidationErrors.user = userValid ? '': 'El usuario es muy corto.';
              break;
          case 'password':
              passwordValid = value.length >= 5;
              fieldValidationErrors.password =  passwordValid ? '': 'El password es muy corto.';
              break;
          default:
              break;
      }

      this.setState({formErrors: fieldValidationErrors,
          nameValid: nameValid,
          userValid: userValid,
          passwordValid: passwordValid}, this.validateForm);
      }

      validateForm() {
          this.setState({formValid: this.state.userValid && this.state.passwordValid});
      }

      errorClass(error) {
          return(error.length === 0 ? '' : 'has-error');
      }

      handleChange(event) {
          const target = event.target;
          const value = target.value;
          const name = target.name;
          this.setState({
              [name]: value
          }, () => { this.validateField(name, value) });
      }

      handleSubmit(event) {
          event.preventDefault();

          let loginFormData = new FormData();
          loginFormData.append('user', this.state.user);
          loginFormData.append('pass', this.state.password);
          if (this.state.formValid){
              axios.post(serverUrl.login, loginFormData, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}} )
              .then(res => {
                  if (res.status === 200) {
                      loginFormData.append('operator', this.state.name);
                      localStorage.setItem('drugstore_id', res.data.body.drugstore_id);
                      loginFormData.append('drugstore_id', res.data.body.drugstore_id);
                      localStorage.setItem('secret', res.data.body.secret);
                      localStorage.setItem('token_magento', res.data.body.token);
                      localStorage.setItem('pharmacy_name', res.data.name);
                      loginFormData.set('pass', res.data.body.secret);
                      this.setUserData(res.data.body)
                      this.getMidData(loginFormData);
                  } else {
                      // shouldn't reach this, axios will always resolve the Promise
                      // when HTTP CODE 200
                      toast.error('Ha ocurrido un error!', toastConfig.errorConf);
                  }
              }).catch(error => {
                // toast.error('Ha ocurrido un error al conectarse con el servidor', toastConfig.errorConf);
              });
          } else {
              toast.warn('Ups! Revisa los campos, por favor', toastConfig.warnConf);
          }
      }

      setUserData (data) {
          localStorage.setItem('pharmacy_name', this.state.pharmacyName);
          localStorage.setItem('user_name', this.state.name);
          localStorage.setItem('token', data.token);
          localStorage.setItem('expiration_date', data.expiration_date);
          localStorage.setItem('user', this.state.user);
      }

      getMidData(loginFormData) {
          axios.post(serverUrl.loginMid, loginFormData, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}} )
              .then(res => {
                  if (res.status === 200) {
                      this.setUserData(res.data.body)
                      this.props.history.push('/dash');
                      toast('Bienvenido! Estamos cargando tus pedidos', toastConfig.greetConfig);
                  } else {
                  toast.error('Ha ocurrido un error!', toastConfig.errorConf);
                  }
          })
      }

      render() {
      return (
          <div className="holder loginComponent">
              <form>
                  <div className="container  cont-logo">
                  <Link to={"/"}>
                      <img className="logo" alt="logo" src={Logo}/>
                  </Link>
                  </div>
                  <div className="form-group text-left">
                      <input type="text" className={`form-control user ${this.state.formErrors.user ? "" : "has-error"}`} name="user" placeholder="Usuario *" value={this.state.user} onChange={this.handleChange}/>
                  </div>

                  <div className="form-group text-left">
                      <input type="password" className={`form-control password ${this.state.formErrors.password ? "" : "has-error"}`} name="password" placeholder="Clave *" value={this.state.password} onChange={this.handleChange}/>
                  </div>

                  <div className="form-group text-left">
                      <input type="text" className={`form-control user ${this.state.formErrors.name ? "" : "has-error"}`} name="name" placeholder="Nombre del operador *" value={this.state.name} onChange={this.handleChange} />
                  </div>

                  <button type="button" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Ingresar</button>
                  <div className="formErrors">
                      {Object.keys( this.state.formErrors).map((fieldName, i) => {
                      if( this.state.formErrors[fieldName].length > 0){
                          return (
                          <p key={i}>{ this.state.formErrors[fieldName]}</p>
                          )
                      } else {
                          return '';
                      }
                      })}
                  </div>
                  <div className="container center register hide">
                      <Link to={"/forgot"} className="bold">Olvidé mi contraseña</Link>
                  </div>
              </form>
          </div>
      );
    }
}
