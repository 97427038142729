import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import XlsxPopulate from 'xlsx-populate';

import Modal from 'react-modal';

import excelIcon from './../assets/excelIcon.svg';

const TableToExcel = (props) => {

    const { orders, month } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const header = ["Pedido", "Origen", "Nombre", "Email", "Telefono", "Total", "Estado", "Fecha de Entrega"];

    const getSheetData = (data, header) => {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    };

    const saveAsExcel = (e) => {
        e.preventDefault();
        let data = [{
            pedido: "",
            origen: "",
            nombre: "",
            email: "",
            telefono: "",
            total: "",
            status:"",
            fechaDeEntrega: "",
        }];

        Object.keys(orders['orders']).map((state) => {
            return orders['orders'][state].map((order) => {
                return data.push({
                    pedido: order['increment_id'],
                    origen: order['store_name'],
                    nombre: order['customer_name'],
                    email: order['customer_email'],
                    telefono: order['customer_phone'],
                    total: order['total'],
                    status: order['status'],
                    fechaDeEntrega: order['shipping_date']
                })
            })
        });

        XlsxPopulate.fromBlankAsync().then((workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                //name archivo .xlsx
                saveAs(res, `Historial_Pedidos_GPSFarma_${month.value}.xlsx`);
            });
        });
        setModalOpen(false);
    };

    return (
        <>
            <Modal
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)'
                    }
                }}
                isOpen={modalOpen}
            >
                <form>
                    <div className="form-group">
                        <strong> <span className="regular">¿Desea guardar el listado completo?</span>
                        </strong></div>
                    <button className="btn confirmSend" onClick={saveAsExcel}><span className="bold">Guardar</span></button>
                    <button className="btn cancel" onClick={()=>setModalOpen(!modalOpen)} >Cancelar</button>
                </form>
            </Modal>
            <button
                style={{
                    backgroundColor: "white",
                    color:"black",
                }}
                className="nav-link text-uppercase"
                type="button"
                onClick={()=>setModalOpen(!modalOpen)}
            >
                <img
                    src={excelIcon}
                    alt="Descargar"
                    style={{
                      width: "45px",
                      marginRight:"8px",
                    }}
                    />
                EXPORTAR LISTADO COMPLETO
            </button>
        </>
    );
};

export default TableToExcel;
