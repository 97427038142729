const serverUrl = {
    loginMid : process.env.REACT_APP_LOGIN_MID,
    info: () => {return process.env.REACT_APP_INFO + localStorage.getItem('token_magento')},
    update : process.env.REACT_APP_UPDATE,
    logOut : process.env.REACT_APP_LOGOUT,
    orders: () => {return process.env.REACT_APP_ORDERS + localStorage.getItem('token')},
    ordersUpdate: (id) => {return process.env.REACT_APP_ORDERS + localStorage.getItem('token') + '&id=' + id},
    login : process.env.REACT_APP_LOGIN,
    imgBaseUrl : process.env.REACT_APP_IMG_URL_BASE,
    wcxChatScript : process.env.REACT_APP_WCX_URL,
    gAnalytics: process.env.REACT_APP_GA,
    forgot: process.env.REACT_APP_FORGOT,
    reset: process.env.REACT_APP_FORGOT_RESET,
};

export default serverUrl;
