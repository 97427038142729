import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from './../assets/fi-rr-notebook.svg'
import logo  from './../assets/logoGPS.svg';
import User  from './../assets/user-circle-blue.svg';
import lupa from './../assets/lupa.svg';
import right from './../assets/chevron-right.svg';
import star from './../assets/estrella.svg';
import list from './../assets/lista.svg';
import sheet from './../assets/sheet.svg';
import { ReactComponent as Close } from './../assets/cerrar.svg';
import download from './../assets/down-arrow.svg'
import discount from './../assets/descuento.svg';
import gear from './../assets/gear.svg';
import axios from 'axios';
import serverUrl from './endpoints';
import './../styles/nav.scss';

export default class Nav extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.toggleMobileSidebar = this.toggleMobileSidebar.bind(this);
        this.toggleSearchBar = this.toggleSearchBar.bind(this);
        this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.getUser = this.getUser.bind(this);
        this.logOut = this.logOut.bind(this);
        this.state = {
            open: false,
            name: '',
            query: '',
            mobileNav: false,
            searchBar: false,
            user: '',
            pharmacyName: ''
        };
    }

    logOut() {
        let token = localStorage.getItem('token');
        axios.post(serverUrl.logOut, {token: token}, {headers: {'Content-Type': 'application/json'}} )
            .then(res => {
               if (res.status === 200) {
                   localStorage.clear();
                    window.location.reload();
               } else {
                //    console.log('error login');
              }
            })
            .catch( error => {
                // may happen that session completelly expires, and user tries to logout
                // that scenario will produce a 404 (?) token invalid exception
                // if (error.message) {
                //     alert(error.message);
                // }

                localStorage.clear();
                window.location.reload();
            });
    }

    toggleClass() {
        const currentState = this.state.open;
        this.setState({ open: !currentState });
    }

    getUser() {
        let userName = localStorage.getItem('user_name');
        let user = localStorage.getItem('user');
        setTimeout(()=>{
            let pharmacyName = localStorage.getItem('pharmacy_name');
            this.setState({ pharmacyName: pharmacyName});
            this.setState({ name: userName });
            this.setState({ user: user });
        }, 500)
    }

    /**
     * Populates search query
     */
    handleSearchSubmit() {
        this.props.onSearch(this.state.query);
    }

    handleSearchQueryChange(event) {
        this.setState({ query: event.target.value });
    }

    toggleMobileSidebar() {
        let currentState = this.state.mobileNav;
        this.setState({ mobileNav: !currentState });
    }

    toggleSearchBar() {
      this.setState({
        searchBar: !this.state.searchBar
      });
    }

    componentDidMount(){
        this.getUser();
    }

    render() {
        return (
        <div>
            <nav className="navbar navbar-expand-lg">
                <div className="navbar-collapse" id="navbarNav">
                    <div className="desktopNav">
                        <Link className="navbar-brand" to={"/"}>
                        <img className="logo" alt="logo" src={logo}/>
                        </Link>
                    </div>
                    <div className="mobileNav">
                        <button className="navbar-toggler" type="button" onClick={this.toggleMobileSidebar}>
                            <img className="list" alt="logo" src={list}/>
                        </button>
                        <Link className="navbar-brand mobile" to={"/"}>
                            <img className="logo" alt="logo" src={logo}/>
                        </Link>
                        <div className="glass">
                            <img alt="Buscar" src={lupa} onClick={this.toggleSearchBar} />
                        </div>
                    </div>
                    <ul className="navbar-nav mr-auto links">
                        <li className="nav-item active">
                            <a className="nav-link end w-100" href="/">Novedades</a>
                        </li>
                        <span>|</span>
                        <li className="nav-item">
                            <img className="percent" alt="ofertas" src={lupa}/>
                            <a className="nav-link" href="/">Ofertas del mes</a>
                        </li>
                        <span>|</span>
                        <li className="nav-item">
                            <img className="percent" alt="ofertas" src={lupa}/>
                            <a className="nav-link w-160" href="/">Material descargable</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-auto link-history">
                        <li className="nav-item">
                            <img className="percent" alt="" src={history}/>
                            <a className="nav-link w-160" href="/history">Historial de Pedidos</a>
                        </li>    
                    </ul> 
                    <ul className="navbar-nav formNuser desktop">
                        <input className="form-control mr-sm-2 search" name="q" type="text" placeholder="N° de pedido" aria-label="Search" value={this.state.query} onChange={this.handleSearchQueryChange} />
                        <button className="search-button" onClick={this.handleSearchSubmit}><span>Buscar</span></button>
                        <li className="nav-item dropdown userbutton">
                                <button className="nav-link dropdown-toggle" id="navbarDropdown"  onClick={this.toggleClass}>
                                    <img className="userCircle" alt="user" src={User}/>
                                    <div className="userNameContainer">
                                        <p className="userText">Usuario: {this.state.user}</p>
                                        <p  className="pharmacyText">Cliente: {this.state.pharmacyName}</p>
                                        <p className="operatorText">Operador: {this.state.name}</p>
                                    </div>
                                </button>
                                <div aria-labelledby="navbarDropdown" className={this.state.open ? 'dropdown-menu block' : 'dropdown-menu'}>
                                    <button className="dropdown-item text-uppercase bold" onClick={this.logOut}>Cerrar sesión</button>
                                </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className={'search-mobile' + (this.state.searchBar ? ' open' : '')}>
                <input className="form-control mr-sm-2 search" name="search-mobile" type="text" placeholder="N° de pedido o Nombre/Apellido del Cliente" aria-label="Search" value={this.state.query} onChange={this.handleSearchQueryChange} />
                <button className="search-mobile-button" onClick={this.handleSearchSubmit}><span>Buscar</span></button>
            </div>
            <div className={this.state.mobileNav ? 'sidebarBack open' : 'sidebarBack'}>
                <div className="collapse navbar-collapse" id="sidebar">
                    <div className="userData">
                        <button className="close" type="button" onClick={this.toggleMobileSidebar}>X</button>
                        <img className="userCircle" alt="user" src={User}/>
                        <span className="branchName bold userMobile">Usuario: {this.state.user}</span>
                        <span className="branchName bold">Cliente: {this.state.pharmacyName}</span>
                        <span className="branchName bold">Operador: {this.state.name}</span>
                    </div>
                    <div className="config">
                        <button>
                            <img className="userCircle" alt="config" src={gear}/>
                            <span>Configuración</span>
                        </button>
                    </div>
                    <div className="sidebarBody">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <img className="icon-list" alt="novedades" src={star}/>
                                <a className="nav-link text-uppercase" href="/">Novedades</a>
                                <img className="chevron" alt="chevron" src={right}/>
                            </li>
                            <li className="nav-item">
                                <img className="icon-list" alt="ofertas" src={discount}/>
                                <a className="nav-link text-uppercase" href="/">Ofertas del mes</a>
                                <img className="chevron" alt="chevron" src={right}/>
                            </li>
                            <li className="nav-item">
                                <img className="icon-list" alt="descargar" src={download}/>
                                <a className="nav-link text-uppercase" href="/">Material descargable</a>
                                <img className="chevron" alt="chevron" src={right}/>

                            </li>
                            <li className="nav-item">
                                <img className="icon-list" alt="reporte" src={sheet}/>
                                <a className="nav-link text-uppercase" href="/">Reporte Resumen de Ventas</a>
                                <img className="chevron" alt="chevron" src={right}/>

                            </li>
                        </ul>
                        <button className="buttonBlue text-uppercase active" onClick={this.logOut}>
                            <Close />
                            Cerrar sesion
                        </button>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
