import React, { Component } from 'react';
import axios from 'axios';
import serverUrl from './endpoints';
import toastConfig from './toastConfig';
import { Link } from 'react-router-dom';
import Logo  from './../assets/logoGPS.svg'; 
import { toast } from 'react-toastify';


export default class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            passwordConfirm: '',
            token: window.location.search.replace('?t=',''),
            formErrors: {
                user: '',
                password: '',
                passwordConfirm: ''
            },
            userValid: true,
            passwordValid: true,
            passwordConfirmValid: true,
            formValid: false,
            processCompleted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let userValid = this.state.userValid;
        let passwordValid = this.state.passwordValid;
        let passwordConfirmValid = this.state.passwordConfirmValid;

        switch(fieldName) {
            case 'user':
                userValid = value.length >= 3;
                fieldValidationErrors.user = userValid
                    ? ''
                    : 'El usuario es muy corto.';
                break;

            case 'password':
                passwordValid = value.length >= 5;
                fieldValidationErrors.password =  passwordValid
                    ? ''
                    : 'El password es muy corto.';
                break;

            case 'passwordConfirm':
                passwordConfirmValid = this.state.passwordConfirm === this.state.password;
                fieldValidationErrors.passwordConfirm = passwordConfirmValid
                    ? ''
                    : 'Las contraseñas no coinciden.';
                break;

            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            userValid: userValid,
            passwordValid: passwordValid,
            passwordConfirmValid: passwordValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.userValid
                && this.state.passwordValid
                && this.state.passwordConfirmValid
                && this.state.token
        });
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        }, () => { this.validateField(name, value) });
    }

    handleSubmit(event) {
        event.preventDefault();

        let loginFormData = new FormData();
        loginFormData.append('user', this.state.user);
        loginFormData.append('pass', this.state.password);
        loginFormData.append('token', this.state.token);

        if (this.state.formValid) {
            axios.post(serverUrl.reset, loginFormData, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}} )
                .then(res => {
                    if (res.status === 200) {
                        this.setState({processCompleted: true});
                        console.log('process completed true');
                        toast.success('Su clave a sido reestablecida', toastConfig.successConf);
                    } else {
                        toast.error('Ha ocurrido un error!', toastConfig.errorConf);
                    }
                }).catch(res => {
                    toast.error(
                        res.message ?? 'Servicio no disponible, intente nuevamente en unos minutos',
                        toastConfig.errorConf
                    );
                    console.error(res);
                });
        } else {
            toast.warn('Ups! Revisa los campos, por favor', toastConfig.warnConf);
        }
    }

    render() {
        if (!this.state.token) {
            return (
              <div className="holder loginComponent">
                  <div className="container cont-logo">
                      <Link to={"/"}>
                          <img className="logo" alt="logo" src={Logo}/>
                      </Link>
                  </div>
                  <form>
                      <p>El proceso de reseteo de contraseña fallo.</p>
                      <div className="container center register">
                           <Link to={"/forgot"} className="bold">Comenzar nuevamente</Link>
                      </div>
                  </form>
              </div>
            );
        }
        return (
            <div className="holder loginComponent">
            { this.state.processCompleted === false
              ? <form>
                    <div className="container cont-logo">
                        <Link to={"/"}>
                            <img className="logo" alt="logo" src={Logo}/>
                        </Link>
                    </div>

                    <div className="form-group text-left">
                        <input type="text" className={`form-control ${this.state.userValid ? "" : "has-error"}`} name="user" placeholder="Usuario" value={this.state.user} onChange={this.handleChange}/>
                    </div>

                    <div className="form-group text-left">
                        <input type="password" className={`form-control ${this.state.passwordValid ? "" : "has-error"}`} placeholder="Ingrese su nueva clave" name="password" value={this.state.password} onChange={this.handleChange} />
                    </div>

                    <div className="form-group text-left">
                        <input type="password" className={`form-control ${this.state.passwordConfirmValid ? "" : "has-error"}`} placeholder="Vuelva a escribir su clave" name="passwordConfirm" value={this.state.passwordConfirm} onChange={this.handleChange} />
                    </div>

                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Cambiar clave</button>
                    <div className="formErrors">
                        {Object.keys( this.state.formErrors).map((fieldName, i) => {
                            if( this.state.formErrors[fieldName].length > 0){
                                return (
                                    <p key={i}>{ this.state.formErrors[fieldName]}</p>
                                )
                            } else {
                                return '';
                            }
                        })}
                    </div>
                    <div className="container center register">
                         <Link to={"/"} className="bold">Volver al login</Link>
                    </div>
                </form>
                : <div>
                    <div className="container cont-logo">
                        <Link to={"/"}>
                            <img className="logo" alt="logo" src={Logo}/>
                        </Link>
                    </div>
                    <p>Proceso finalizado</p>
                    <Link to={"/"} className="bold">Volver al login</Link>
                  </div>
              }
            </div>
        );
    }
}
