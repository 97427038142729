import React, { Component } from 'react'
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import './../../styles/comments.scss';
import Moment from 'react-moment';
import Modal from 'react-modal';
import { ReactComponent as Chat } from './../../assets/charla.svg';
import { ReactComponent as Close } from './../../assets/mas.svg';
import { ReactComponent as Back } from './../../assets/chevron-left.svg';
import { ReactComponent as Checked } from './../../assets/comprobar.svg';
import { ReactComponent as InTravel } from './../../assets/en-transito.svg';
import { ReactComponent as Canceled } from './../../assets/cancelado.svg';
import { ReactComponent as Available } from './../../assets/disponible.svg';
import { ReactComponent as Delivered } from './../../assets/entregado.svg';

import serverUrl from './../endpoints';
import toastConfig from './../toastConfig';
import { toast } from 'react-toastify';

export default class Comments extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openCancelModal = this.openCancelModal.bind(this);
        this.closeCancelModal = this.closeCancelModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitCancel = this.handleSubmitCancel.bind(this);
        this.handleModalShowHideCancel = this.handleModalShowHideCancel.bind(this);
        this.handleSubmitPopUp = this.handleSubmitPopUp.bind(this);
        this.state = {
            showHide: false,
            open: false,
            collapse: false,
            modal: false,
            cancelModal: false,
            order: this.props.comments,
            changeStatusOrder: this.props.changeStatusOrder,
            classTarget: this.props.classTarget,
            orderId: this.props.orderId,
            state: this.props.nextState,
            status: this.props.nextStatus,
            comment: '',
            visible: '0',
            notify: '0',
        };

        this.customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
        this.customStyles_ = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                transform: 'translate(-50%, -50%)'
            }
        };
        this.buttonStyle = {
            content: {
                backgroundColor: 'transparent',
                color: '$font_base',
                border: '1px solid $font_base'
            }
        }

    }

    toggleClass() {
        const currentState = this.state.open;
        this.setState({ open: !currentState });
    };

    toggleCollapse() {
        const collapseState = this.state.collapse;
        this.setState({ collapse: !collapseState });
    };

    openModal() {
        this.setState({ modal: true });
    }

    closeModal() {
        this.setState({ modal: false });
    }

    openCancelModal() {
        this.setState({ cancelModal: true });
    }

    closeCancelModal() {
        this.setState({ cancelModal: false });
    }
    logoType(status) {
        if (status === 'en_transito') {
            return <InTravel />
        }
        if (status === 'disponible') {
            return <Available />

        }
        if (status === 'entregada') {
            return <Delivered />
        }
        if (status === 'cancelado') {
            return <Canceled />
        }
        if (status === 'no_retirada') {
            return <Canceled />
        }
    }
    statusType(comment) {
        if (comment.comment === null) {
            if (comment.status === 'en_transito') {
                return 'Marcada como en transito por el operador.'
            }
            if (comment.status === 'disponible') {
                return 'Marcada como disponible por el operador.'

            }
            if (comment.status === 'entregada') {
                return 'Marcado como entregado por el operador.';
            }
            if (comment.status === 'cancelado') {
                return 'Cancelada por el operador.';
            }
            if (comment.status === 'no_retirada') {
                return 'Pedido no retirado.';
            }
        } else {
            return comment.comment;
        }
    }
    statusName(status) {
        if (status === 'en_transito') {
            return 'En transito'
        }
        if (status === 'disponible') {
            return 'Disponible'

        }
        if (status === 'entregada') {
            return 'Entregado'

        }
        if (status === 'cancelado') {
            return 'Cancelada'

        }
        if (status === 'no_retirada') {
            return 'No retirado'
        }
    }
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({ comment: value });
    }

    handleModalShowHide() {
        this.setState({ showHide: true })
    }

    handleModalShowHideCancel() {
        this.setState({ showHide: false })
    }

    handleSubmit(event) {

        event.preventDefault();

        if (this.state.status === "entregada") {

            this.setState({ showHide: true });

        }
        if (this.state.status !== "entregada") {

            let updateOrderData = {
                orderId: this.state.orderId,
                state: this.state.state,
                status: this.state.status,
                comment: this.commentFormatter(),
                visible: this.state.visible,
                notify: this.state.notify
            };
            axios.post(serverUrl.ordersUpdate(this.state.orderId), updateOrderData, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    this.closeModal();
                    window.location.reload();
                } else {
                    toast.error('Ha ocurrido un error!', toastConfig.errorConf);
                }
            })
        }

    }

    handleSubmitPopUp(event) {

        event.preventDefault();
        if (this.state.status === "entregada") {

            let updateOrderData = {
                orderId: this.state.orderId,
                state: this.state.state,
                status: this.state.status,
                comment: this.commentFormatter(),
                visible: this.state.visible,
                notify: this.state.notify
            };
            axios.post(serverUrl.ordersUpdate(this.state.orderId), updateOrderData, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    this.closeModal();
                    window.location.reload();
                } else {
                    toast.error('Ha ocurrido un error!', toastConfig.errorConf);
                }
            })
        }
    }

    handleSubmitCancel(event) {
        event.preventDefault();

        let updateOrderData = {
            orderId: this.state.orderId,
            state: 'processing',
            status: 'cancelado',
            comment: this.commentCancelFormatter(),
            visible: this.state.visible,
            notify: this.state.notify
        };
        axios.post(serverUrl.ordersUpdate(this.state.orderId), updateOrderData, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    this.closeCancelModal();
                    window.location.reload();
                } else {
                    toast.error('Ha ocurrido un error!', toastConfig.errorConf);
                }
            })
    }
    commentFormatter() {
        const user = localStorage.getItem('user_name');
        let nextState = this.state.status;
        let opDetail = 'Marcada como ' + nextState + ' por el operador - ' + user + '. \n';
        if (this.state.comment) {
            return opDetail + this.state.comment;
        } else {
            return opDetail;
        }
    }
    commentCancelFormatter() {
        const user = localStorage.getItem('user_name');
        let opDetail = 'Marcada como cancelada por el operador - ' + user + '. \n';

        return this.state.comment ? opDetail + this.state.comment : opDetail;
    }
    render() {
        const comments = this.state.order.comments;
        const commentList = comments.map((comment, index) => {
            let comCollect = [];
            if (comment.status === 'en_transito' || (comment.status === 'disponible' && comment.comment !== 'Reminder Send.') || comment.status === 'entregada' || comment.status === 'cancelado' || comment.status === 'no_retirada') {
                let com = <div className="stateStep" key={index}>
                    <div className="left">
                        {this.logoType(comment.status)}
                        <div className="desc">
                            <span className="bold text-uppercase">{this.statusName(comment.status)}</span>
                            <p>{this.statusType(comment)}</p>
                        </div>
                    </div>
                    <div className="time">
                        <p><span className="bold"><Moment format="DD/MM/YYYY HH:mm">{comment.created_at}</Moment></span></p>
                    </div>
                    <Modal isOpen={this.state.modal} onRequestClose={this.closeModal} style={this.customStyles} contentLabel="Change State Modal" ariaHideApp={false}>
                        <div className="top">
                            <button className="close" onClick={this.closeModal}><Close /></button>
                            <button className="back" onClick={this.closeModal}><Back /></button>
                        </div>
                        <div className="header">
                            <h3 className="text-uppercase">Confirmar cambio de estado de pedido</h3>
                            <div className="order">
                                <div className="orderNum">
                                    <strong>Pedido:</strong><span className="line">{this.props.comments.increment_id}</span>
                                </div>
                                <div className="orderState">
                                    <strong>Estado a confirmar:</strong><span className="state"><Checked /> {this.statusName(this.state.status)}</span>
                                </div>
                            </div>
                        </div>
                        <form>
                            <div className="form-group text-left">
                                <label>Comentario <span className="regular">(opcional)</span></label>
                                <textarea type="text" name="comment" placeholder="Dejá tu comentario" value={this.state.comment} onChange={this.handleChange} />
                            </div>
                            <button className="btn" onClick={this.handleSubmit}>Confirmar como <span className="bold">{this.statusName(this.state.status)}</span></button>
                            <button className="btn cancel" onClick={this.closeModal}>Cancelar</button>
                        </form>
                    </Modal>
                    <Modal isOpen={this.state.cancelModal} onRequestClose={this.closeCancelModal} style={this.customStyles} contentLabel="Cancel Modal" ariaHideApp={false}>
                        <div className="top">
                            <button className="close" onClick={this.closeCancelModal}><Close /></button>
                            <button className="back" onClick={this.closeModal}><Back /></button>
                        </div>
                        <div className="header">
                            <h3 className="text-uppercase">Confirmar cambio de estado de pedido</h3>
                            <div className="order">
                                <div className="orderNum">
                                    <strong>Pedido:</strong><span className="line">{this.props.comments.increment_id}</span>
                                </div>
                                <div className="orderState">
                                    <strong>Estado a confirmar:</strong><span className="state"><Checked /> {this.statusName('cancelado')}</span>
                                </div>
                            </div>
                        </div>
                        <form>
                            <div className="form-group text-left">
                                <label>Comentario <span className="regular">(opcional)</span></label>
                                <textarea type="text" name="comment" placeholder="Dejá tu comentario" value={this.state.comment} onChange={this.handleChange} />
                            </div>
                            <button className="btn confirmCancel" onClick={this.handleSubmitCancel}>Confirmar como <span className="bold">Cancelado</span></button>
                            <button className="btn cancel" onClick={this.closeCancelModal}>Cancelar</button>
                        </form>
                    </Modal>
                    <Modal
                        style={this.customStyles_}
                        isOpen={this.state.showHide}
                        onRequestClose={this.handleModalShowHideCancel}
                        ariaHideApp={false}
                    >
                        <form>
                            <div className="form-group">
                                <strong> <span className="regular">Le enviaremos un mail al consumidor final
                                    avisándole  que su pedido ya fue entregado. ¿Estas seguro?</span>
                                </strong></div>
                            <button className="btn confirmSend" onClick={this.handleSubmitPopUp}>Confirmar como <span className="bold">Entregado</span></button>
                            <button className="btn cancel" onClick={this.handleModalShowHideCancel} >Cancelar</button>
                        </form>
                    </Modal>
                </div>;
                comCollect.push(com);
            }
            if (comment.status === 'sin_informar'&& comment.comment !== null) {
                let com = <div className="stateStep" key={index}>
                    <div className="left">
                        <div className="desc">
                            <span className="bold text-uppercase">Mercadopago</span>
                            <div className="mercadopago">{ReactHtmlParser(comment.comment)}</div>
                        </div>
                    </div>
                    <div className="time">
                        <p><span className="bold"><Moment format="DD/MM/YYYY HH:mm">{comment.created_at}</Moment></span></p>
                    </div>
                    <Modal isOpen={this.state.modal} onRequestClose={this.closeModal} style={this.customStyles} contentLabel="Change State Modal">
                        <div className="top"><button onClick={this.closeModal}><Close /></button></div>
                        <div className="header">
                            <h3 className="text-uppercase">Confirmar cambio de estado de pedido</h3>
                            <div className="order">
                                <strong>Pedido:</strong><span className="line">{this.props.comments.increment_id}</span>
                                <strong>Estado a confirmar:</strong><span className="state"><Checked /> {this.statusName(this.state.status)}</span>
                            </div>
                        </div>
                        <form>
                            <div className="form-group text-left">
                                <label>Comentario <span className="regular">(opcional)</span></label>
                                <textarea type="text" name="comment" placeholder="Dejá tu comentario" value={this.state.comment} onChange={this.handleChange} />
                            </div>
                            <button className="btn" onClick={this.handleSubmit}>Confirmar como <span className="bold">{this.statusName(this.state.status)}</span></button>
                            <button className="btn cancel" onClick={this.closeModal}>Cancelar</button>
                        </form>
                    </Modal>
                    <Modal isOpen={this.state.cancelModal} onRequestClose={this.closeCancelModal} style={this.customStyles} contentLabel="Cancel Modal">
                        <div className="top"><button onClick={this.closeModal}><Close /></button></div>
                        <div className="header">
                            <h3 className="text-uppercase">Confirmar cancelación de pedido</h3>
                            <div className="order">
                                <strong>Pedido:</strong><span className="line">{this.props.comments.increment_id}</span>
                                <strong>Estado a confirmar:</strong><span className="state">Cancelado</span>
                            </div>
                        </div>
                        <form>
                            <div className="form-group text-left">
                                <label>Comentario <span className="regular">(opcional)</span></label>
                                <textarea type="text" name="comment" placeholder="Dejá tu comentario" value={this.state.comment} onChange={this.handleChange} />
                            </div>
                            <button className="btn confirmCancel" onClick={this.handleSubmitCancel}>Confirmar como <span className="bold">Cancelado</span></button>
                            <button className="btn cancel" onClick={this.closeCancelModal}>Cancelar</button>
                        </form>
                    </Modal>
                </div>;
                comCollect.push(com);
            }
            return comCollect;
        }
    );
        return (
            <div className="collapse comments" id={this.state.classTarget}>
                <div className="title text-uppercase bold">
                    <Chat />
                    Mensajes
                </div>
                <div className="bodyState">
                    {commentList}
                    {/* <div className="stateStep button">
                        <button className="btn ">Dejar un comentario</button>
                    </div> */}
                </div>
                { this.state.changeStatusOrder ?
                    <div className={`changeState ${(this.state.order.state === 'processing' && this.state.order.status !== 'cancelado' && this.state.order.status !== 'no_retirada') ? "" : "none"}`} >
                        <button className="btn  bold" onClick={this.openModal}>Cambiar estado a <span className="bold text-uppercase">{this.statusName(this.state.status)}</span> <Available /></button>
                    </div>
                    : null
                }
                {
                    this.state.changeStatusOrder ?
                        <div className={`changeState ${this.state.order.status !== 'cancelado' && this.state.order.status !== 'no_retirada' ? "" : "none"}`} >
                            <button className="btn cancel bold" onClick={this.openCancelModal}>Cambiar estado a <span className="bold text-uppercase">Cancelado</span> <Canceled /></button>
                        </div>
                    : null
                }
            </div>
        );
    }
}
