import React, { Component } from 'react'
import axios from 'axios';
// import Advisor from './advisor';
import right from './../assets/chevron-right.svg';
import List from './list.component';
import { ReactComponent as InTravel } from './../assets/en-transito.svg';
import { ReactComponent as Canceled } from './../assets/cancelado.svg';
import { ReactComponent as Available } from './../assets/disponible.svg';
import { ReactComponent as Delivered } from './../assets/entregado.svg';
import { toast } from 'react-toastify';
import toastConfig from './toastConfig';
import serverUrl from './endpoints';
import './../styles/dash.scss';

export default class Dash extends Component {
    constructor(props) {
        super(props);
        this.applySearchFilter = this.applySearchFilter.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this._updateSearchResults = this._updateSearchResults.bind(this);
        this._updateSearch = this._updateSearch.bind(this);
        this._filterOrders = this._filterOrders.bind(this);
        this.applyDataFilter = this.applyDataFilter.bind(this);
        this._orderStatusList = [
          "en_transito",
          "disponible",
          "entregada",
          "cancelado",
          "no_retirada"
        ];

        this.state = {
            loading: true,
            orders: {},
            search: {
              query: props.searchQuery,
              count: 0,
              results: []
            },
            selectedValue: this.props.selectedValue,
            drugstoreInfo: {}
        };
    }

    componentDidMount() {
        this.getDrugstoreInfo();
        this.getOrders();
    }

    clearSearch() {
        // just clean up the search results to start over
        this._updateSearch({
          query: '',
          results: {},
          count: 0
        });

        // @TODO: refactor entire tab system
        // This workaround needs to be done so, when you clear a search
        // the active tab becomes inactive and the first one is selected
        //
        // Causes: given the app is currently built to parse and control the Orders
        // within this component, when the search gets refreshed/cleaned, only the <List /> comps
        // are re-rendered (not the entire dash component).
        // This causes a dissociation between the `active` marked tab,
        // and which `<List />` comp is shown right after the search is cleaned.
        let tabs = document.getElementById('myTab');
        tabs.getElementsByClassName('active')[0].classList.remove('active');
        tabs.getElementsByTagName('a')[0].classList.add('active');

        this.getOrders();
    }

    /**
     * @param  {string} haystack search value
     * @param  {object[]} orders list of orders
     * @return {object[]} orders matching the haystack
     */
    _filterOrders = ( haystack, orders ) => {
      let filtered = {};

      Object.keys(orders)
        .forEach( status => {
          filtered[status] = orders[status].filter(order => {
            return order.hasOwnProperty('increment_id')
              && order.hasOwnProperty('customer_name')
              && ( order.increment_id.indexOf(haystack) >= 0
                || order.customer_name.indexOf(haystack) >= 0
                || order.customer_name.toUpperCase().indexOf(haystack) >= 0
                || order.customer_name.toLowerCase().indexOf(haystack) >= 0
              );
          });
        }
      );
      return filtered;
    };

    /**
     * @param {object[]} filteredOrders list of orders matching search criteria
     */
    _updateSearchResults = (filteredOrders) => {
      let counter = 0;

      Object.keys(filteredOrders)
        .forEach( status => {
          counter = counter + filteredOrders[status].length;
        });

      this._updateSearch({
        results: filteredOrders,
        count: counter
      });

      this.setState( { orders: filteredOrders });
    }

    /**
     * @param  {Object} searchData
     */
    _updateSearch = ( searchData ) => {
      this.setState({
        search: {
          ...this.state.search,
          ...searchData
        }
      });
    }

    applyDataFilter(orders){
      
      let filtered = {};
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);

      Object.keys(orders)
        .forEach( status => {
          filtered[status] = orders[status].filter(order => {
            return order.hasOwnProperty('created_at')
              && (new Date(order.created_at) >= startDate);
          });
        }
      );
      
      return filtered;
    }

    applySearchFilter(orders) {

      if (this.state.search.query !== '' && orders) {
        let list = this._filterOrders( this.state.search.query, orders );
        this._updateSearchResults(list);
        return list;
      }
      return orders;
    }

    getDrugstoreInfo() {
        axios.get(
          serverUrl.info()
        ).then(res => {
           if (res && (res.status === 200)) {
                this.setState({drugstoreInfo: res.data});
                this.createChatScript(this.state);
                localStorage.setItem('pharmacy_name', res.data.name);
           } else {
               toast.error('Ha ocurrido un error cargando tus datos de tienda.', toastConfig.errorConf)
           }
        }).catch( error => {
          console.log('dash:getDrugstoreInfo:catch:error', error);
        });
    }

    /**
     * Reshape data structure so all orders are within a proper `status` node
     * [
     *   "<order_status>": [ {...}, {...}]
     *   "en_transito": [ {"increment_id":"xxxx", ...}, {...}]
     * ]
     *
     * @param {object[]}
     * @return {string[object[]]}
     */
    _normalizeOrderDataStructure(orders) {
        var shaped = orders.reduce((mapping, order) => {
            if (!mapping[order.status]) {
              mapping[order.status] = [];
            }

            mapping[order.status].push(order);
            return mapping;
        }, {});

        // ensure to populate all status,
        // no matter if we have orders or not
        this._orderStatusList.forEach( status => {
          if (!shaped[status]) {
            shaped[status] = [];
          }
        });
        return shaped;
    }

    getOrders() {
      axios.interceptors.request.use( config => { this.setState({ loading: true }); return config; });
      axios.interceptors.response.use( config => { this.setState({ loading: false }); return config; });

      return axios.get(serverUrl.orders())
        .then( res => {
          if (res.status === 200) {
            let orderGroup = this._normalizeOrderDataStructure(
                Object.values(res.data)
            );

            return orderGroup;
          } else {
            toast.error('Ha ocurrido un error cargando tus pedidos.', toastConfig.errorConf)
          }
        })
        .then( (orders) => {
          if (!orders) {
            return;
          }
          orders = this.applyDataFilter(orders);
          orders = this.applySearchFilter(orders);
          this.setState({ orders: orders });
        })
        .catch( error => {
          console.log('dash:getOrders:error', error);
          if ( error.message === 'token_expired') {
            toast.error('Su sesion ha caducado, por favor inicie sesion nuevamente.', toastConfig.errorConf)
          }
        });
    }

    createChatScript(state) {
        var s = document.createElement("script");
        s.src = serverUrl.wcxChatScript;
        document.head.appendChild(s);
        window.addEventListener("wcxChatReady", function () {
            var _wchat = window._wcchat;
            _wchat.onReady(() => {
                _wchat.setUserData([
                    // inicia la sesion en el chat
                    { nameField: "customer_name", value: state.drugstoreInfo.name },
                    { nameField: "customer_email", value: state.drugstoreInfo.email },
                ]);
            _wchat.setFields([
                // se envia como actualizacion una vez que se genera el ticket - el type debe ser "customer" o "case"
                { type: "customer", field: "nro_de_cliente_dds", value: state.drugstoreInfo.code }
            ]);
          });
        });
    };

    render() {
        return (
            <div className="holder">
                { this.state.search.query &&
                  <div>Buscando Ordenes por "{this.state.search.query}" ({this.state.search.count} resultados) <button onClick={this.clearSearch}><strong>eliminar</strong></button></div>
                }
                <ul className="nav nav-pills nav-fill" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link text-uppercase active bold" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                        <InTravel />
                            <span>en transito</span>
                            <img className="chevron" alt="chevron" src={right}/>
                        </a>
                        { (this.state.search.query && this.state.orders.en_transito && this.state.orders.en_transito.length > 0 ) &&
                          <strong>{ this.state.orders.en_transito.length }</strong>
                        }
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-uppercase" id="available-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                        <Available />
                            <span>disponible</span>
                            <img className="chevron" alt="chevron" src={right}/>
                        </a>
                        { (this.state.search.query && this.state.orders.disponible && this.state.orders.disponible.length > 0 ) &&
                          <strong>{ this.state.orders.disponible.length }</strong> }
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-uppercase"  id="delivered-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                        <Delivered />
                            <span>entregado</span>
                            <img className="chevron" alt="chevron" src={right}/>
                        </a>
                        { (this.state.search.query && this.state.orders.entregada && this.state.orders.entregada.length > 0 ) &&
                        <strong>{ this.state.orders.entregada.length }</strong> }
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-uppercase"  id="cancelled-tab" data-toggle="tab" href="#cancel" role="tab" aria-controls="contact" aria-selected="false">
                        <Canceled />
                           <span>cancelado</span>
                           <img className="chevron" alt="chevron" src={right}/>
                        </a>
                        { (this.state.search.query && this.state.orders.cancelado && this.state.orders.cancelado.length > 0 ) &&
                        <strong>{ this.state.orders.cancelado.length }</strong> }
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-uppercase"  id="unshiped-tab" data-toggle="tab" href="#no_retirada" role="tab" aria-controls="contact" aria-selected="false">
                        <Canceled />
                           <span>No retirado</span>
                           <img className="chevron" alt="chevron" src={right}/>
                        </a>
                        { (this.state.search.query && this.state.orders.no_retirada && this.state.orders.no_retirada.length > 0 ) &&
                        <strong>{ this.state.orders.no_retirada.length }</strong> }
                    </li>
                </ul>
                {!this.state.loading ? (
                  <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                          <List byOrder="en_transito" orders={this.state.orders.en_transito} changeStatusOrder={true}/>
                      </div>
                      <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                          <List byOrder="disponible" orders={this.state.orders.disponible} changeStatusOrder={true}/>
                      </div>
                      <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                          <List byOrder="entregada" orders={this.state.orders.entregada} changeStatusOrder={true}/>
                      </div>
                      <div className="tab-pane fade" id="cancel" role="tabpanel" aria-labelledby="contact-tab">
                          <List byOrder="cancelado" orders={this.state.orders.cancelado} changeStatusOrder={true}/>
                      </div>
                      <div className="tab-pane fade" id="no_retirada" role="tabpanel" aria-labelledby="contact-tab">
                          <List byOrder="no_retirada" orders={this.state.orders.no_retirada} changeStatusOrder={true}/>
                      </div>
                  </div>
                ) : null}
            </div>
        );
    }
}
