import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
 // eslint-disable-next-line
import $ from 'jquery';
 // eslint-disable-next-line
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ReactGA from 'react-ga';
import serverUrl from './components/endpoints';


ReactGA.initialize(serverUrl.gAnalytics);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();
