import React, { useMemo, useState } from 'react'
import './../styles/stateList.scss';
import  filters  from './../assets/filter.svg';

import Row from './list/row';

const List = props => {
    const {
      orders = [],
      byOrder,
      changeStatusOrder,
    } = props;

    const [areFiltersExpanded, setExpandFilters] = useState(0);

    const content = useMemo(
      () => {
          // @TODO add reference to show up a loading indicator based on parent `axios` call
          if (!orders) {
            return (
              <span className="noResult">Aguarde unos instantes, estamos actualizando la información…</span>
            );
          }

          if (orders.length === 0) {
            return (
              <span className="noResult">Al momento no tenés pedidos en este estado.</span>
            );
          } else {
            return orders.map((order, index) => <Row data={order}
                key={index}
                changeStatusOrder={changeStatusOrder}
                byOrder={byOrder} />);
          }
    }, [
      orders,
      changeStatusOrder,
      byOrder
    ]);

    return (
      <div className="stateList">
        <div className="listFilter" data-tab-status-name={byOrder}>
          <span className="bold text-uppercase">Disponible</span>
          <div className="nav-item dropdown">
            <button className="nav-link dropdown-toggle bold" id="navbarDropdown" onClick={() => setExpandFilters(!areFiltersExpanded)}>
                <img src={filters} alt="Filtros"/>
                Ordenar por precio
            </button>
            <div aria-labelledby="navbarDropdown" className={areFiltersExpanded ? 'dropdown-menu block' : 'dropdown-menu'}>
              <button className="dropdown-item" href="javascript;">De mayor a menor $$$ - $</button>
              <button className="dropdown-item" href="javascript;">De menor a mayor $ - $$$</button>
            </div>
          </div>
          <button className="nav-link dropdown-toggle selected" id="navbarDropdown">
            $$$ - $
            <span className="dismissFilter">X</span>
          </button>
          <hr/>
        </div>
        <div className="listBody">{content}</div>
    </div>);
}

export default List;
