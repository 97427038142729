import React, { Component} from 'react';
import Moment from 'react-moment';
import CurrencyFormat from 'react-currency-format';
import WordLimit from 'react-word-limit';
import logo  from './../assets/logoGPS.svg'; 
import './../styles/print.scss';


export default class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: this.props.order,
        };
    }
    cropAfter(string){
        var s = string;   
        s = s.substring(0, s.indexOf('.'));
        return s;
    }
    percentageDiscount(item) {
        var base_discount = Math.round(((parseFloat(item.original_price) - parseFloat(item.price)) * 100) / parseFloat(item.original_price));
        var percent = (Math.round(((parseFloat(item.total) * 100) / parseFloat(item.sub_total)) - 100) - base_discount ) * -1;
        if(isNaN(percent) || percent === 0){
            return '-';
        }else{
            return percent + '%';
        }
    };
    hasDiscount(finalPrice, originalPrice) {
        if (finalPrice === originalPrice) {
            return  <div><CurrencyFormat value={finalPrice} displayType={'text'} decimalScale={2} prefix={'$'} /></div>
        } else {
            return  <div><CurrencyFormat value={finalPrice} displayType={'text'} decimalScale={2} prefix={'$'} /><CurrencyFormat value={originalPrice} displayType={'text'} decimalScale={2} prefix={'$'} className="originalPrice"/></div>
        }
    }
    render () {
        let order = this.state.order;
        return (
            <div className="body-content">
                <div className="body" ref={this.printRef}  id={order.entity_id}>
                        <strong className="resume text-uppercase">Resumen de pedido</strong>
                        <div className="logo">
                            <img alt="logo" src={logo}/>
                        </div>
                        <table className="headerTable">
                            <thead className="text-uppercase bold">
                                <tr>
                                    <th>Pedido</th>
                                    <th>Origen</th>
                                    <th>Nombre y apellido</th>
                                    <th>Fecha de entrega<br/></th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{order.increment_id}</td>
                                    <td><WordLimit limit={12}>{order.store_name}</WordLimit></td>
                                    <td>{order.customer_name}</td>
                                    <td><Moment format="DD/MM/YYYY HH:mm">{order.shipping_date}</Moment></td>
                                    <td><strong><CurrencyFormat value={order.total} displayType={'text'} decimalScale={2} prefix={'$'} /></strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="modalTable">
                            <thead className="text-uppercase bold">
                                <tr>
                                    <th className="p-left">Producto</th>
                                    <th>Código de barras</th>
                                    <th>Precio unitario</th>
                                    <th>Cantidad</th>
                                    <th>Subtotal</th>
                                    <th>Descuento</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.order_items.map((item, i) => 
                                    <tr key={i}>
                                        <td className="bold"><WordLimit limit={48}>{item.name}</WordLimit></td>
                                        <td>{item.ean}</td>
                                        <td>{this.hasDiscount(item.price, item.original_price)}</td>
                                        <td className="center">{this.cropAfter(item.quantity)}</td>
                                        <td><CurrencyFormat value={item.sub_total} displayType={'text'} decimalScale={2} prefix={'$'} /></td>
                                        <td className="center">{this.percentageDiscount(item.discount)}</td>
                                        <td><CurrencyFormat value={item.total} displayType={'text'} decimalScale={2} prefix={'$'} /></td>
                                    </tr>
                                    )}
                            </tbody>
                        </table>   
                    <div className="signSection">
                        <div className="signRow">
                            <div className="sign">
                                Fecha de entrega
                            </div>
                            <div className="sign">
                                Firma, aclaración y DNI del comprador o autorizado.
                            </div>
                        </div>
                        <div className="adviceRow">
                            <span>
                                Al firmar el comprobante declaro haber recibido conforme el producto.
                            </span>
                            <strong className="sign text-uppercase">
                                Importante
                            </strong>
                            <p>
                                En caso de contracargos por parte del titular de la tarjeta, se presentará este documento como prueba de la entrega del producto.
                            </p>
                        </div>
                    </div>
                </div>
        </div>
        )
    }
}
