
import React, { Component } from "react";
import './../styles/footer.scss';


export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            open: false
        };
    }
    toggleClass() {
        const currentState = this.state.open;
        this.setState({ open: !currentState });
    };
    render() {
        return (
            <footer className="mt-auto footer">
                
            </footer>
        );
    }
}
