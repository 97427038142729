import React, { useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import toastConfig from './toastConfig';

/**
 * flagged as deprecated, this is 99% a leftover
 * no one, within the codebase, will/should and must try to test the domain against ACME
 *
 * @deprecated
 * @return {Boolean}
 */
 // let's pretend the company is called ACME
export const isCorrectDomain = url => {
  return /^([^/]+:)?\/{2,3}[^/]+?(\.ACME\.com|\.acme)(:|\/|$)/i.test(url);
};

function Interceptor() {
  // id prevent duplicate toastr
  // const customId = "idRedirectAuthError";

  useEffect(() => {
    let token = localStorage.getItem('token');

    const onRequest = config => {
      if (!config.headers.hasOwnProperty("Authorization")) {
        if (token) {
          config.headers.Authorization = token;
        }
      } else if (!config.headers.Authorization) {
        delete config.headers.Authorization;
      }

      return config;
    };

    const onRequestError = error => {
      return Promise.reject(error);
    }

    const authIntercept = axios.interceptors.request.use(onRequest, onRequestError);

    const removeRequestInterceptor = () => {
      axios.interceptors.request.eject(authIntercept);
    };

    const onResponse = response => {
      return response;
    }

    const onResponseError = (error) => {
      if (error.response) {
        const code = error.response.status;
        const data = error.response.data;
        let shouldCleanUpStorage = false;

        if (code === 401) {
          if (data.message) {
            if (data.message === 'Invalid Password' || data.message === 'Invalid Username or Password') {
              toast.warn('Usuario o contraseña incorrectos.', toastConfig.warnConf);
            }
            // Rare response from forgot password. Especified, waiting for info.
            if (data.message === 'Invalid User/Pass') {
              toast.error('Usuario o contraseña incorrectos.', toastConfig.warnConf);
            }
          }

          shouldCleanUpStorage = true;
        } else if (code === 301) {
            toast.warn('Su sesión ha expirado, por favor inicie sesión nuevamente.', toastConfig.warnConf);
            shouldCleanUpStorage = true;
        } else {
            let message = "Algo salió mal.";
            if (code === 403) {
              message = "No estás autorizado";
              shouldCleanUpStorage = true;
            }  else if (code === 503) {
              message = "El sitio está en mantenimiento. Volvé a intentarlo en unos minutos!";
            } else if (code === 404) {
              // you shouldn't see this, unless there's something terrible wrong in M2/MID
            } else if (error.message) {
              message = error.message;
            }

            toast.error(message, toastConfig.errorConf)
        }

        if (shouldCleanUpStorage) {
          localStorage.clear();
        }

        return Promise.reject(error);
      }
    };

    const errorIntercept = axios.interceptors.response.use(onResponse, onResponseError);

    const removeErrorInterceptor = () => {
      axios.interceptors.request.eject(errorIntercept);
    };

    return () => {
      removeRequestInterceptor();
      removeErrorInterceptor();
    };
  }, []);

  return <React.Fragment />;
}

export default Interceptor;
