import React, { Component } from 'react'
import axios from 'axios';
import serverUrl from './endpoints';
import toastConfig from './toastConfig';
import { Link} from 'react-router-dom';
import Logo  from './../assets/logoGPS.svg';
import { toast } from 'react-toastify';
import './../styles/login.scss';

export default class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            formErrors: { user: '' },
            userValid: false,
            formValid: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let userValid = this.state.userValid;

        switch(fieldName) {
            case 'user':
                userValid = value.length >= 3;
                fieldValidationErrors.user = userValid ? '': 'El usuario es muy corto.';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            userValid: userValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({ formValid: this.state.userValid });
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        }, () => { this.validateField(name, value) });
    }

    handleSubmit(event) {
        event.preventDefault();
        let loginFormData = new FormData();
        loginFormData.append('user', this.state.user);

        if (this.state.formValid) {
            axios.post(
                  serverUrl.forgot,
                  loginFormData,
                  { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
              ).then(res => {
                  if (res.status === 200) {
                    toast.success('Te hemos enviado un email con información!', toastConfig.successConf);
                    this.setState({message: 'Te hemos enviado un email con información!'});
                  } else {
                      toast.error('Ha ocurrido un error!', toastConfig.errorConf);
                  }
              }).catch(res => {
                  console.error('Error when connecting to the server', res);
              });
        } else {
            toast.warn('Ups! Revisa los campos, por favor', toastConfig.warnConf);
        }
    }

    render() {
        return (
            <div className="holder loginComponent">
                <form>
                    <div className="container  cont-logo">
                        <Link to={"/"}>
                            <img className="logo" alt="logo" src={Logo}/>
                        </Link>
                    </div>

                    <div className="form-group text-left">
                        <input type="text" className={`form-control user ${this.state.formErrors.user ? "" : "has-error"}`} name="user" placeholder="Usuario *" value={this.state.user} onChange={this.handleChange}/>
                    </div>

                    <div className="formErrors">
                        {Object.keys( this.state.formErrors).map((fieldName, i) => {
                            if( this.state.formErrors[fieldName].length > 0) {
                                return (
                                    <p key={i}>{ this.state.formErrors[fieldName]}</p>
                                )
                            } else {
                                return '';
                            }
                        })}
                    </div>

                    <button type="button" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Enviar</button>

                    <div className="container center register">
                         <Link to={"/"} className="bold">Volver al login</Link>
                    </div>
                </form>
            </div>
        );
    }
}
