import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './styles/bootstrap.min.css';
import './styles/normalize.css';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import Login from "./components/login.component";
import Nav from "./components/nav.component";
import Dash from "./components/dash.component";
import Interceptor from "./components/interceptor";
import Footer from "./components/footer.component";
import requireAuth from "./components/private-routes.component";
import Forgot from "./components/forgot.component";
import ResetPassword from "./components/reset-password.component";
import HistoryOrder from './components/historyOrder';

class App extends Component {
    constructor(props) {
        super(props);

        this.handleOnSearch = this.handleOnSearch.bind(this);

        this.state = {
            logged: false,
            selectedValue: null,
            query: ''
        }
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.setState({logged:true });
        } else {
            this.setState({logged:false });
        }
    }

    handleOnSearch(value) {
        this.setState({query: value});
    }

    selectedValueHandler = (selectedValue) => {
        // console.log('selectedValueHandler', selectedValue);
        this.setState({
            selectedValue: selectedValue
        })
    }

    render() {
        const { selectedValue, query } = this.state;
        const LoginContainer = () => (
            <div>
                <Route exact path="/" component={requireAuth(Dash)} />
                <Route path="/sign-in" component={Login} />
            </div>
        )

        const DefaultContainer = () => (
            <div>
                <Nav selectedValueHandler={this.selectedValueHandler} onSearch={this.handleOnSearch} />
                <Route exact path="/" component={requireAuth(Dash)} />
                <Route exact path="/history" render={requireAuth(() => <HistoryOrder />)}/>
                <Route path='/dash' render={requireAuth(() => <Dash selectedValue={selectedValue} searchQuery={query} />)} />
                <Footer />
            </div>
        )

        const ForgotContainer = () => (
            <div>
                <Route exact path="/" component={requireAuth(Dash)} />
                <Route path='/forgot' render={() => <Forgot selectedValue={selectedValue} />} />
            </div>
        )

        const ResetPasswordContainer = () => (
            <div>
                <Route exact path="/" component={requireAuth(Dash)} />
                <Route path='/forgot' render={() => <Forgot selectedValue={selectedValue} />} />
                <Route path='/reset' render={() => <ResetPassword />} />
            </div>
        )

        return (
            <Router>
                <Interceptor />
                <div className="App d-flex flex-column min-vh-100">
                    <Switch>
                      <Route exact path="/(sign-in)" component={LoginContainer} />
                      <Route exact path="/(forgot)" component={ForgotContainer} />
                      <Route exact path="/(reset)" component={ResetPasswordContainer} />
                      <Route component={DefaultContainer} />
                    </Switch>
                </div>
                <ToastContainer />
            </Router>
        );
    }
}

export default App;
