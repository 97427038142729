import React, { useState, useRef } from 'react';

import CurrencyFormat from 'react-currency-format';
import Modal from 'react-modal';
import Moment from 'react-moment';
import WordLimit from 'react-word-limit';
import ReactToPrint from 'react-to-print';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import ComponentToPrint from './../printOrder';
import { parsePromotion } from '../../utils/parseLabel';

import Comments from './comments.component';
import serverUrl from './../endpoints';

import up from './../../assets/up-arrow.svg';
import down from './../../assets/down-arrow.svg';
import pdf from './../../assets/pdf.svg';
import comments from './../../assets/comments.svg';
import { ReactComponent as Close } from './../../assets/mas.svg';

const Row = props => {
  const [isExpanded, setExpanded] = useState(0);
  const [isPrintModalOpened, setPrintModalOpened] = useState(false);
  const printReference = useRef(null);

  const {
    data: order,
    byOrder,
    aria = 'collapseExample',
    changeStatusOrder = true
  } = props;

  const modalStyle = {
      content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          height: '800px',
          overflow: 'auto',
          transform: 'translate(-50%,-50%)'
      }
  };

  /**
   * Get print modal body by id, converts to img and then to pdf
   *
   * @param  {string} div the outter div that holds the content to be printed
   * @param  {string} increment_id Order Increment Id to be used in the filename
   */
  const printDocument = (div, increment_id) => {
      window.scrollTo(0,0);
      const input = document.getElementById(div);

      html2canvas(input, {scale:0.7})
        .then((canvas) => {
          let imgData = canvas.toDataURL('image/png');
          let pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG', 0, 0);
          pdf.save("pedido-" + increment_id + ".pdf");
        });
  }

  const commentsByOrderState = (orderState, order, aria) => {
      if (orderState === 'en_transito') {
          return  <Comments comments={order} classTarget={aria + order.entity_id} orderId={order.entity_id} nextState={'processing'} nextStatus={'disponible'} changeStatusOrder={changeStatusOrder}/>;
      }
      if (orderState === 'disponible') {
          return  <Comments comments={order} classTarget={aria + order.entity_id} orderId={order.entity_id} nextState={'complete'} nextStatus={'entregada'} changeStatusOrder={changeStatusOrder}/>;
      }
      if (orderState === 'entregada') {
          return  <Comments comments={order} classTarget={aria + order.entity_id} orderId={order.entity_id} nextState={'processing'} nextStatus={'disponible'} changeStatusOrder={changeStatusOrder}/>;
      }
      if (orderState === 'cancelado') {
          return  <Comments comments={order} classTarget={aria + order.entity_id} orderId={order.entity_id} changeStatusOrder={changeStatusOrder}/>;
      }
      if (orderState === 'no_retirada') {
          return  <Comments comments={order} classTarget={aria + order.entity_id} orderId={order.entity_id} changeStatusOrder={changeStatusOrder}/>;
      }
  }

  const cropAfter = string => {
      var s = string;
      s = s.substring(0, s.indexOf('.'));
      return s;
  }

  const percentageDiscount = item => {
      var base_discount = Math.round(((parseFloat(item.original_price) - parseFloat(item.price)) * 100) / parseFloat(item.original_price));
      var percent = (Math.round(((parseFloat(item.total) * 100) / parseFloat(item.sub_total)) - 100) - base_discount ) * -1;

      if(isNaN(percent) || percent === 0){
          return '-';
      } else {
          return percent + '%';
      }
  };

  /**
   * Compare prices to check if discount exist
   *
   * @deprecated
   * @param {float} finalPrice
   * @param {float} originalPrice
   */
  const hasDiscount = (finalPrice, originalPrice) => {
    if (finalPrice === originalPrice) {
      return <div><CurrencyFormat value={finalPrice} displayType={'text'} decimalScale={2} prefix={'$'} /></div>
    } else {
      return <div><CurrencyFormat value={finalPrice} displayType={'text'} decimalScale={2} prefix={'$'} /><CurrencyFormat value={originalPrice} displayType={'text'} decimalScale={2} prefix={'$'} className="originalPrice"/></div>
    }
  }

  /**
   * Builds the discount label
   *
   * @param  {[type]} string
   */
  const hasLabel = string => {
    if (string === null || string === '') {
      return '-';
    }

    const label = parsePromotion(string);
    return (
        <div className="label-discount">
                <span>{label.extraLineBefore}</span>
            <div className="label-strong">
                <span>{label.discount} </span>
                <span>{label.extraLineAfter}</span>
            </div>
            <span>{label.qty}</span>
        </div>
    );
  }

  return (
      <div className="pedRow">
        <div className="pedColumn">
            <div className="detail" data-toggle="collapse" data-target={'#'+aria + order.entity_id} role="button" aria-expanded="false" aria-controls={aria + order.entity_id} onClick={() => { setExpanded(!isExpanded) }}>
                <div className="mobileDeatilHolder">
                    <table className="">
                        <thead className="text-uppercase bold">
                            <tr>
                                <th>Pedido</th>
                                <th>Origen</th>
                                <th>Nombre</th>
                                <th>Email</th>
                                <th>Telefono</th>
                                <th>Total</th>
                                <th>Fecha de entrega<br/></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={"order"}>{order.increment_id}</td>
                                <td className={"origin"}>{order.store_name.split(" ")[0]}</td>
                                <td className={"name"}>{order.customer_name}</td>
                                <td className={"email"}>{order.customer_email}</td>
                                <td className={"telephone"}>{order.customer_phone}</td>
                                <td className={"total"}><CurrencyFormat value={order.total} displayType={'text'} decimalScale={2} prefix={'$'} /></td>
                                <td className={"date"}><Moment format="DD/MM/YYYY HH:mm">{order.shipping_date}</Moment></td>
                            </tr>
                        </tbody>
                    </table>
                    <img src={isExpanded ? up : down} className="" alt="Toggle Arrow"/>
                </div>
                <div className="mobileActions">
                    <button className="text-uppercase border-bottom" onClick={() => { setPrintModalOpened(true) }}>
                        <img src={pdf} alt="Descargar"/>
                    </button>
                    <button className="text-uppercase comments-btn">
                        <img src={comments} alt="Comentarios"/>
                    </button>
                </div>
            </div>
            <div className="collapse collapseHead" id={aria + order.entity_id}>
                <div className="card card-body">
                    <table className="desktopTable">
                        <thead className="text-uppercase bold">
                            <tr>
                                <th className="p-left">Producto</th>
                                <th>Código de barras</th>
                                <th>Precio unitario</th>
                                <th>Cantidad</th>
                                <th>Oferta</th>
                                <th>Descuento</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.order_items.map((item, i) =>
                                <tr key={i}>
                                   <td className="bold p-left">
                                        <img className="productImg" src={serverUrl.imgBaseUrl + item.img} alt="Product"/>
                                        {item.name}
                                    </td>
                                    <td className="barcode">{item.ean}</td>
                                    <td className="unitPrice"><CurrencyFormat value={item.original_price} displayType={'text'} decimalScale={2} prefix={'$'} /></td>
                                    <td className="center quantityDesktop">{cropAfter(item.quantity)}</td>
                                    <td className="center">{hasLabel(item.label)}</td>
                                    <td className="center percentageDiscount">{percentageDiscount(item)}</td>
                                    <td><CurrencyFormat value={item.total} displayType={'text'} decimalScale={2} prefix={'$'} /></td>
                                </tr>
                                )}
                        </tbody>
                    </table>
                    <div className="modileTableAltHeader">
                        <span className="p-left label text-uppercase bold">Productos:</span>
                        <div className="productsNameList">
                            {order.order_items.map((item, i) =>
                                    <div key={i} className="productName">
                                        <img className="productImg" src={serverUrl.imgBaseUrl + item.img} alt="Product"/>
                                        <WordLimit limit={36}>{item.name}</WordLimit>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="mobileTable">
                        {order.order_items.map((item, i) =>
                            <div className="productIt" key={i}>
                                <div className="thead text-uppercase bold">
                                    <div className="tr">
                                        <div className="th">Código de barras:</div>
                                        <div className="th">Precio unitario:</div>
                                        <div className="th">Cantidad:</div>
                                        <div className="th">Oferta:</div>
                                        <div className="th">Descuento:</div>
                                        <div className="th">Total:</div>
                                    </div>
                                </div>
                                <div className="tbody">
                                        <div className="tr"key={i}>
                                            <div className="td">{item.ean}</div>
                                            <div className="td"><CurrencyFormat value={item.original_price} displayType={'text'} decimalScale={2} prefix={'$'}/></div>
                                            <div className="td">{cropAfter(item.quantity)}</div>
                                            <div className="td ofertSticker">{hasLabel(item.label)}</div>
                                            <div className="td">{percentageDiscount(item)}</div>
                                            <div className="td"><CurrencyFormat value={item.total} displayType={'text'} decimalScale={2} prefix={'$'} /></div>
                                        </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="totalRow bold">
                        <div className="total text-uppercase">
                            Total:
                        </div>
                        <div className="amount totalModal">
                          <CurrencyFormat value={order.total} displayType={'text'} decimalScale={2} prefix={'$'} />
                        </div>
                    </div>
                </div>
            </div>
            {commentsByOrderState(byOrder, order, aria)}
        </div>
        <div className="actions">
            <button className="text-uppercase" onClick={() => { setPrintModalOpened(true) }}>
                <img src={pdf} alt="Descargar"/>
                Imprimir/Descargar
            </button>
            <Modal isOpen={isPrintModalOpened} onRequestClose={() => { setPrintModalOpened(false) }} style={modalStyle} contentLabel="Print or Save Modal" ariaHideApp={false}>
                <div className="modal-top">
                    <button className="close" onClick={() => setPrintModalOpened(false) }><Close /></button>
                </div>
                <div className="mobileDesc">
                    Resumen de orden<strong> N°:{order.entity_id}</strong>
                </div>
                <ComponentToPrint ref={printReference} order={order} />
                <div className="modal-footer">
                    <ReactToPrint trigger={() => {
                        return <button className="btn print">Imprimir</button>}}
                        content={() => printReference.current}/>
                    <button className="btn save" onClick={() => { printDocument(order.entity_id, order.increment_id) }}>Guardar</button>
                </div>
            </Modal>
        </div>
      </div>
  );
};

export default Row;
